import React from "react"
import ProjectList from "../components/project-list"
import Header from "../components/header"
import Footer from "../components/footer"
import { Swiper, SwiperSlide } from "swiper/react"
import CustomHelmet from "../components/custom-helmet"
import { graphql } from "gatsby"

// Import Swiper styles
import "swiper/swiper.scss"
import "./index.scss"

export default function Home({ data }) {
  return (
    <div>
      <CustomHelmet title="Lunta Labs - E-commerce, web applications and websites" />
      <Header position="absolute" colorTheme="white" />
      <div className="hero">
        <div className="hero__cta">
          <h1 className="hero__cta__main-title">Fresh Web Applications</h1>
          <h2 className="hero__cta__secondary-title">
            We bring your ideas live
          </h2>
        </div>
        <div className="hero__video-container">
          <video
            className="hero__video"
            width="600"
            height="600"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src="video-2.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="hero__image-container"></div>
      </div>

      <div className="index__services">
        <h2>
          We build excellent digital experiences which don't explode your budget
        </h2>
        <div className="index__service-icons">
          <div className="index__service">
            <img src="icon-e-commerce.svg" className="index__service-icon" />
            <h3>E-commerce</h3>
            <div className="index__service__description">
              You want to sell online? We'll get your eCommerce site running
              without exploding your budget.
            </div>
          </div>
          <div className="index__service">
            <img src="icon-websites.svg" className="index__service-icon" />
            <h3>Websites</h3>
            <div className="index__service__description">
              Why to build rocketship, if you just need plain old website. We'll
              help you get your site up with neat design.
            </div>
          </div>
          <div className="index__service">
            <img src="icon-cogwheel.svg" className="index__service-icon" />
            <h3>Tailored web-applications</h3>
            <div className="index__service__description">
              When web application is your product, you most often need tailored
              solution. We'll help you get your product working fast and nicely.
            </div>
          </div>
        </div>
      </div>

      {false && (
        <div className="services--mobile">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            onSlideChange={() => console.log("slide change")}
            onSwiper={swiper => console.log(swiper)}
            breakpoints={{
              "480": {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              "800": {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <div className="index__service">
                <img
                  src="icon-e-commerce.svg"
                  className="index__service-icon"
                />
                <h3>E-commerce</h3>
                <div className="index__service__description">
                  You want to sell online? We'll get your eCommerce site running
                  without exploding your budget.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="index__service">
                <img src="icon-websites.svg" className="index__service-icon" />
                <h3>Websites</h3>
                <div className="index__service__description">
                  Why to build rocketship, if you just need plain old website.
                  We'll help you get your site up with neat design
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="index__service">
                <img src="icon-cogwheel.svg" className="index__service-icon" />
                <h3>Tailored web-applications</h3>
                <div className="index__service__description">
                  When web application is your product, you most often need
                  tailored solution. We'll help you get your product working
                  fast and nicely
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}

      <div className="index__projects">
        <h2>Check out what we have created recently</h2>
        <ProjectList projects={data.allContentfulProject.edges} />
      </div>

      <div className="index__clients">
        <h2>Our clients</h2>
        <div className="index__client-logos">
          {data.allContentfulClient.edges.map(client => {
            return (
              <div className="index__logo-container">
                <img
                  className="index__client-logo"
                  src={client.node.logo.file.url}
                  alt=""
                />
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const indexQuery = graphql`
  query IndexQuery {
    allContentfulProject {
      edges {
        node {
          slug
          title
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulClient {
      edges {
        node {
          clientName
          logo {
            file {
              url
            }
          }
        }
      }
    }
  }
`
