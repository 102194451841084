import React from "react"

import "./project-list.scss"

interface Props {
  projects: Array<any>
}

export default function ProjectList(props: Props) {
  const projects = props.projects.map((project, index) => (
    <a
      href={"/portfolio/" + project.node.slug}
      className="project-list__project"
      key={"project-" + index}
    >
    <h2 className="project-list__title">{project.node.title}</h2>

      {project.node.thumbnail && (
        <img
          className="project-list__image"
          src={project.node.thumbnail.file.url}
        />
      )}
    </a>
  ))

  return <div className="project-list">{projects}</div>
}
